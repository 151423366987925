import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";
import Modal from "react-modal";
import MailChimpFormContainer from "./Newsletter";

import LogoAstra from "../../assets/images/logo/logo-astra.png";
import LogoAstraNobg from "../../assets/images/logo/logo-astra-nobg.png";

Modal.setAppElement("#root");

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }

  openModal() {
    console.log("Attempting to open modal");
    this.setState({ modalIsOpen: true }, () => {
      console.log("Modal is open");
    });
  }

  closeModal() {
    console.log("Attempting to close modal");
    this.setState({ modalIsOpen: false });
  }

  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  render() {
    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    const { logo, color = "default-color" } = this.props;
    let logoUrl;
    if (logo === "light") {
      logoUrl = <img src={LogoAstraNobg} alt="Astra Innovations LLC" />;
    } else {
      logoUrl = <img src={LogoAstra} alt="Astra Innovations LLC" />;
    }

    return (
      <header
        className={`header-area formobile-menu header--transparent ${color}`}
      >
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <a href="/">{logoUrl}</a>
            </div>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <ul className="mainmenu">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li className="has-droupdown">
                  <Link to="/service">Service</Link>
                  <ul className="submenu">
                    {/*
                    <li>
                      <Link to="/service-details2">Software Development</Link>
                    </li>
                    */}
                    <li>
                      <Link to="/service-details">Solar Energy</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </nav>
            {/*                         
                        <div className="header-btn">
                            <a
                                className="rn-btn"
                                href="#"
                                onClick={this.openModal}
                            >
                                {/* add email mailing list feature here 
                                <span>Subscribe</span>
                            </a>
                        </div> 
*/}

            <Modal
              isOpen={this.state.modalIsOpen}
              onRequestClose={this.closeModal}
              contentLabel="Subscribe Modal"
              className="subscribe-modal"
              overlayClassName="subscribe-overlay"
            >
              <MailChimpFormContainer />
              <button
                onClick={this.closeModal}
                style={{
                  backgroundColor: "#f9004d",
                  color: "#333", // Dark grey text color
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "20px", // Rounded corners
                  cursor: "pointer",
                  fontSize: "16px",
                  transition: "background-color 0.3s ease",
                  display: "block",
                  margin: "auto",
                }}
                onMouseOver={(e) =>
                  (e.target.style.backgroundColor = "#ef0963")
                }
                onMouseOut={(e) => (e.target.style.backgroundColor = "#f9004d")}
              >
                Close
              </button>
            </Modal>
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default Header;
